export default [
  {
    path: '/master/ingredients/list',
    name: 'master-ingredients-list',
    component: () => import('@/views/admin/masters/ingredients/ingredients-list/ListIngredients.vue'),

  },
  {
    path: '/master/ingredients/edit/:id',
    name: 'master-ingredients-edit',
    component: () => import('@/views/admin/masters/ingredients/ingredients-list/EditIngredients.vue'),
  }


  ,
  {
    path: '/master/categories/list',
    name: 'master-categories-list',
    component: () => import('@/views/admin/masters/categories/categories-list/ListCategories.vue'),

  },
  {
    path: '/master/categories/edit/:id',
    name: 'master-categories-edit',
    component: () => import('@/views/admin/masters/categories/categories-list/EditCategories.vue'),
  }


  , {
    path: '/master/sidedish/list',
    name: 'master-sidedish-list',
    component: () => import('@/views/admin/masters/sidedish/sidedish-list/ListSidedish.vue'),

  }
  ,
  {
    path: '/master/sidedish/edit/:id',
    name: 'master-sidedish-edit',
    component: () => import('@/views/admin/masters/sidedish/sidedish-list/EditSidedish.vue'),
  }
  ,
  {
    path: '/master/pincode/list',
    name: 'master-pincode-list',
    component: () => import('@/views/admin/masters/pincode/pincode-list/ListPincode.vue'),

  }

  ,
  {
    path: '/master/pincode/edit/:id',
    name: 'master-pincode-edit',
    component: () => import('@/views/admin/masters/pincode/pincode-list/EditPincode.vue'),
  }

  ,
  {
    path: '/master/review/list',
    name: 'master-review-list',
    component: () => import('@/views/admin/masters/review/review-list/ListReview.vue'),

  }

  ,
  {
    path: '/master/review/edit/:id',
    name: 'master-review-edit',
    component: () => import('@/views/admin/masters/review/review-list/EditReview.vue'),
  }
  ,
  {
    path: '/master/food/list',
    name: 'master-food-list',
    component: () => import('@/views/admin/masters/food/food-list/ListFood.vue'),

  }

  ,
  {
    path: '/master/food/edit/:id',
    name: 'master-food-edit',
    component: () => import('@/views/admin/masters/food/food-list/EditFood.vue'),
  }


  ,
  {
    path: '/master/food/add',
    name: 'master-food-add',
    component: () => import('@/views/admin/masters/food/food-list/AddFood.vue'),
  }

  ,

  {
    path: '/master/schedule/list',
    name: 'master-schedule-list',
    component: () => import('@/views/admin/masters/schedule/schedule-list/Listschedule.vue'),

  }

  ,
  {
    path: '/master/schedule/edit/:id',
    name: 'master-schedule-edit',
    component: () => import('@/views/admin/masters/schedule/schedule-list/Editschedule.vue'),
  }
  ,
  {
    path: '/master/food/addspec/:id',
    name: 'master-food-addspec',
    component: () => import('@/views/admin/masters/food/food-list/AddFoodSpec.vue'),
  }
  ,

  {
    path: '/master/orders/list',
    name: 'master-orders-list',
    component: () => import('@/views/admin/masters/orders/orders-list/ListOrders.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },

  }

  ,

  {
    path: '/master/orders/view/:id',
    name: 'master-orders-view',
    component: () => import('@/views/admin/masters/invoice/invoice-view/InvoicePreview.vue'),

  }



  ,

  {
    path: '/master/members/list',
    name: 'master-members-list',
    component: () => import('@/views/admin/masters/members/members-list/ListMembers.vue'),

  }


  ,

  {
    path: '/master/admins/list',
    name: 'master-admins-list',
    component: () => import('@/views/admin/masters/admins/admins-list/ListAdmins.vue'),

  }
  ,
  {
    path: '/master/delivery/list',
    name: 'master-delivery-list',
    component: () => import('@/views/admin/masters/delivery/delivery-list/ListDelivery.vue'),

  }
]
